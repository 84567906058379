<template>
  <div>
    <b-card class="mb-0" no-body>
      <template #header>
        <div class=" d-flex justify-content-start" style="align-items: center;">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            class="d-flex"
            style="align-items:center;"
            @click="back()"
          >
            <feather-icon icon="ArrowLeftCircleIcon" class="mr-50" size="23" />
            <span class="align-middle">{{$t('general_buttons.back')}}</span>
          </b-button>
          <h4 class="mb-0 ml-2">{{$t('equity_bond.titles.selected_amend_details')}}</h4>
        </div>
      </template>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-table
          v-if="tradeDetailDatas.length > 0"
          hover
          ref="refEquityBondTable"
          class="position-relative"
          :items="tradeDetailDatas"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('equity_bond.messages.no_matching_trade_records')"
          :current-page="currentPage"
          :per-page="perPage"
          :tbody-tr-class="legendClass"
          @row-clicked="viewSelectedTrade"
          caption-top
        >
          <!-- Column: show_details -->
          <!-- <template #cell(show_details)="row">
              <b-form-checkbox
                size="md"
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
                name="check-button"
                button
                v-b-tooltip.hover
                :title="
                  row.detailsShowing
                    ? 'Hide Trade Controls'
                    : 'Show Trade Controls'
                "
                button-variant="flat-primary"
              >
                <feather-icon
                  :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
                />
              </b-form-checkbox>
            </template> -->

          <!-- Column: ID -->
          <template #cell(ID)="data">
            <b
              ><span class="font-weight-bold text-center">
                <strong>{{ data.item.ID ? data.item.ID : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: EntryType -->
          <template #cell(EntryType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.EntryType ? data.item.EntryType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: InstrumentType -->
          <template #cell(InstrumentType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.InstrumentType ? data.item.InstrumentType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OrderDate -->
          <template #cell(OrderDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderDate ? dateFormat(data.item.OrderDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TradeDate -->
          <template #cell(TradeDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TradeDate ? dateFormat(data.item.TradeDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Amount -->
          <template #cell(Amount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Amount ? formatPrice(data.item.Amount, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SettlementDate -->
          <template #cell(SettlementDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SettlementDate
                    ? dateFormat(data.item.SettlementDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Client -->
          <template #cell(Client)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Client ? data.item.Client : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Account -->
          <template #cell(Account)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Account ? data.item.Account : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SecurityDescription -->
          <template #cell(SecurityDescription)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SecurityDescription
                    ? data.item.SecurityDescription
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: BuySell -->
          <template #cell(BuySell)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.BuySell ? data.item.BuySell : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Quantity -->
          <template #cell(Quantity)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Quantity ? formatPrice(data.item.Quantity, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Price -->
          <template #cell(Price)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Price ? formatPrice(data.item.Price, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TraderNote -->
          <template #cell(TraderNote)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TraderNote ? data.item.TraderNote : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CommissionRate -->
          <template #cell(CommissionRate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CommissionRate
                    ? formatPrice(data.item.CommissionRate, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CommissionAmount -->
          <template #cell(CommissionAmount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CommissionAmount
                    ? formatPrice(data.item.CommissionAmount, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Tax -->
          <template #cell(Tax)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Tax ? formatPrice(data.item.Tax, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ClientAmount -->
          <template #cell(ClientAmount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ClientAmount
                    ? formatPrice(data.item.ClientAmount, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Ccy -->
          <template #cell(Ccy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Ccy ? data.item.Ccy : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CounterpartyRate -->
          <template #cell(CounterpartyRate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CounterpartyRate
                    ? formatPrice(data.item.CounterpartyRate, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CounterpartyCommission -->
          <template #cell(CounterpartyCommission)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CounterpartyCommission
                    ? formatPrice(data.item.CounterpartyCommission, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CounterpartyAmount -->
          <template #cell(CounterpartyAmount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CounterpartyAmount
                    ? formatPrice(data.item.CounterpartyAmount, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Counterparty -->
          <template #cell(Counterparty)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Counterparty ? data.item.Counterparty : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CounterpartyAccount -->
          <template #cell(CounterpartyAccount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CounterpartyAccount
                    ? data.item.CounterpartyAccount
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TcProfit -->
          <template #cell(TcProfit)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TcProfit ? formatPrice(data.item.TcProfit, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CounterpartyTrader -->
          <template #cell(CounterpartyTrader)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CounterpartyTrader
                    ? data.item.CounterpartyTrader
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TcUser -->
          <template #cell(TcUser)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.TcUser ? data.item.TcUser : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SecurityAccount -->
          <template #cell(SecurityAccount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SecurityAccount ? data.item.SecurityAccount : "-"
                }}</strong>
              </span></b
            >
          </template>


          <!-- Column: OrderTime -->
          <template #cell(OrderTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderTime ? timeFormat(data.item.OrderTime) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ExecutionTime -->
          <template #cell(ExecutionTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ExecutionTime
                    ? timeFormat(data.item.ExecutionTime)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OrderTakenVia -->
          <template #cell(OrderTakenVia)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderTakenVia ? data.item.OrderTakenVia : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OrderGivenThrough -->
          <template #cell(OrderGivenThrough)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderGivenThrough
                    ? data.item.OrderGivenThrough
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: OrderPrice -->
          <template #cell(OrderPrice)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.OrderPrice
                    ? formatPrice(data.item.OrderPrice, 2)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Margin -->
          <template #cell(Margin)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Margin != 0 ? true : false }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ExchangeTraded -->
          <template #cell(ExchangeTraded)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ExchangeTraded ? data.item.ExchangeTraded : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Isin -->
          <template #cell(Isin)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Isin ? data.item.Isin : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Prop -->
          <template #cell(Prop)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Prop != 0 ? true : false }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ActingCapacity -->
          <template #cell(ActingCapacity)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ActingCapacity ? data.item.ActingCapacity : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SCF UTI -->
          <template #cell(TcUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.TcUti ? data.item.TcUti : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: UniqueLinkId -->
          <template #cell(UniqueLinkId)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                }}</strong>
              </span></b
            >
          </template>


          <!-- Column: SavedBy -->
          <template #cell(SavedBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SavedBy ? data.item.SavedBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SaveDate -->
          <template #cell(SaveDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SaveDate ? dateFormat(data.item.SaveDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SaveTime -->
          <template #cell(SaveTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SaveTime ? timeFormat(data.item.SaveTime) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendedBy -->
          <template #cell(AmendedBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendedBy ? data.item.AmendedBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentDate -->
          <template #cell(AmendmentDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentDate
                    ? dateFormat(data.item.AmendmentDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentTime -->
          <template #cell(AmendmentTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentTime
                    ? timeFormat(data.item.AmendmentTime)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentReason -->
          <template #cell(AmendmentReason)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentReason ? data.item.AmendmentReason : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: EntryType2 & Main Entry -->
          <template #cell(EntryType2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ "Main Entry" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Cancelled -->
          <template #cell(Cancelled)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Cancelled ? data.item.Cancelled : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelledBy -->
          <template #cell(CancelledBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelledBy ? data.item.CancelledBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelDate -->
          <template #cell(CancelDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelDate ? dateFormat(data.item.CancelDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelTime -->
          <template #cell(CancelTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelTime ? timeFormat(data.item.CancelTime) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelReason -->
          <template #cell(CancelReason)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelReason ? data.item.CancelReason : "-"
                }}</strong>
              </span></b
            >
          </template>
        </b-table>

        <div class="mx-2 mb-2" v-if="tradeDetailDatas.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{$t('general_text.showing')}} {{ dataMeta.from }} {{$t('general_text.to')}} {{ dataMeta.to }} {{$t('general_text.of')}}
                {{ dataMeta.of }} {{$t('general_text.entries')}}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <div v-if="tradeDetailDatas.length == 0">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <span
                ><strong>{{$t('equity_bond.titles.not_found_trades')}}</strong> <br />
                {{$t('equity_bond.messages.no_amended_trades_found')}}</span
              >
            </div>
          </b-alert>
        </div>
      </b-overlay>

      {{ /*View Selected Trade for trades modal*/ }}
      <ViewSelectedTrade ref="ViewSelectedTrade" />
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import equityStoreModule from "../EquityStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import ViewSelectedTrade from "./ViewSelectedDetail.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    ViewSelectedTrade,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        equityStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.detailId = this.$route.params.id;
    this.getAmendDetailsData(this.$route.params.id);
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      tradeDetailDatas: [],
      detailId: null,
      filterOn: [
        "TcUser",
        "Counterparty",
        "Client",
        "OrderGivenThrough",
        "OrderTakenVia",
      ],
      tableColumns: [
        // { key: "show_details", label: "" },
        {
          key: "ID",
          label: "ID",
          // thClass: "text-left",
          tdClass: "text-center",
          // thStyle: 'width: 30%',
          // sortable: true,
          class: "text-start",
          variant: "primary",
        },
        {
          key: "EntryType",
          label: this.$t('equity_bond.table.entry_type'),
          class: "text-start",
        },
        {
          key: "InstrumentType",
          label: this.$t('equity_bond.table.type'),
          class: "text-start",
        },
        {
          key: "OrderDate",
          label: this.$t('equity_bond.table.order_date'),
          class: "text-start",
        },
        {
          key: "TradeDate",
          label: this.$t('equity_bond.table.trade_date'),
          class: "text-start",
        },
        {
          key: "Amount",
          label: this.$t('equity_bond.table.amount'),
          class: "text-start",
        },
        {
          key: "SettlementDate",
          label: this.$t('equity_bond.table.settlement_date'),
          class: "text-start",
        },
        {
          key: "Client",
          label: this.$t('equity_bond.table.client'),
          class: "text-start",
        },
        {
          key: "Account",
          label: this.$t('equity_bond.table.account'),
          class: "text-start",
        },
        {
          key: "SecurityDescription",
          label: this.$t('equity_bond.table.security_desc'),
          class: "text-start",
        },
        {
          key: "BuySell",
          label: this.$t('equity_bond.table.buy_sell'),
          class: "text-start",
        },
        {
          key: "Quantity",
          label: this.$t('equity_bond.table.quantity'),
          class: "text-start",
        },
        {
          key: "Price",
          label: this.$t('equity_bond.table.price'),
          class: "text-start",
        },
        {
          key: "TraderNote",
          label: this.$t('equity_bond.table.note'),
          class: "text-start",
        },
        {
          key: "CommissionRate",
          label: this.$t('equity_bond.table.client_rate'),
          class: "text-start",
        },
        {
          key: "CommissionAmount",
          label: this.$t('equity_bond.table.client_comission'),
          class: "text-start",
        },
        {
          key: "Tax",
          label: this.$t('equity_bond.table.tax_amount'),
          class: "text-start",
        },
        {
          key: "ClientAmount",
          label: this.$t('equity_bond.table.client_amount'),
          class: "text-start",
        },
        {
          key: "Ccy",
          label: this.$t('equity_bond.table.ccy'),
          class: "text-start",
        },
        {
          key: "CounterpartyRate",
          label: this.$t('equity_bond.table.counterparty_rate'),
          class: "text-start",
        },
        {
          key: "CounterpartyCommission",
          label: this.$t('equity_bond.table.counterparty_commission'),
          class: "text-start",
        },
        {
          key: "CounterpartyAmount",
          label: this.$t('equity_bond.table.counterparty_amount'),
          class: "text-start",
        },
        {
          key: "Counterparty",
          label: this.$t('equity_bond.table.counterparty'),
          class: "text-start",
        },
        {
          key: "CounterpartyAccount",
          label: this.$t('equity_bond.table.counterparty_account'),
          class: "text-start",
        },
        {
          key: "TcProfit",
          label: this.$t('equity_bond.table.profit'),
          class: "text-start",
        },
        {
          key: "CounterpartyTrader",
          label: this.$t('equity_bond.table.counterparty_trader'),
          class: "text-start",
        },
        {
          key: "TcUser",
          label: this.$t('equity_bond.table.sfc_user'),
          class: "text-start",
        },
        {
          key: "SecurityAccount",
          label: this.$t('equity_bond.table.security_account'),
          class: "text-start",
        },
        {
          key: "OrderTime",
          label: this.$t('equity_bond.table.order_time'),
          class: "text-start",
        },
        {
          key: "ExecutionTime",
          label: this.$t('equity_bond.table.execution_time'),
          class: "text-start",
        },
        {
          key: "OrderTakenVia",
          label: this.$t('equity_bond.table.order_taken_via'),
          class: "text-start",
        },
        {
          key: "OrderGivenThrough",
          label: this.$t('equity_bond.table.order_given_through'),
          class: "text-start",
        },
        {
          key: "OrderPrice",
          label: this.$t('equity_bond.table.order_price'),
          class: "text-start",
        },
        {
          key: "Margin",
          label: this.$t('equity_bond.table.margin'),
          class: "text-start",
        },
        {
          key: "ExchangeTraded",
          label: this.$t('equity_bond.table.exchange_traded'),
          class: "text-start",
        },
        {
          key: "Isin",
          label: this.$t('equity_bond.table.security_isin'),
          class: "text-start",
        },
        {
          key: "Prop",
          label: this.$t('equity_bond.table.prop'),
          class: "text-start",
        },
        {
          key: "ActingCapacity",
          label: this.$t('equity_bond.table.acting_capacity'),
          class: "text-start",
        },
        {
          key: "TcUti",
          label: this.$t('equity_bond.table.sfc_uti'),
          class: "text-start",
        },
        {
          key: "UniqueLinkId",
          label: this.$t('equity_bond.table.unique_link_id'),
          class: "text-start",
        },
        {
          key: "SavedBy",
          label: this.$t('equity_bond.table.saved_by'),
          class: "text-start",
        },
        {
          key: "SaveDate",
          label: this.$t('equity_bond.table.save_date'),
          class: "text-start",
        },
        {
          key: "SaveTime",
          label: this.$t('equity_bond.table.save_time'),
          class: "text-start",
        },
        {
          key: "AmendedBy",
          label: this.$t('equity_bond.table.amended_by'),
          class: "text-start",
        },
        {
          key: "AmendmentDate",
          label: this.$t('equity_bond.table.amendment_date'),
          class: "text-start",
        },
        {
          key: "AmendmentTime",
          label: this.$t('equity_bond.table.amendment_time'),
          class: "text-start",
        },
        {
          key: "AmendmentReason",
          label: this.$t('equity_bond.table.amendment_reason'),
          class: "text-start",
        },
        {
          key: "EntryType2",
          label: this.$t('equity_bond.table.entry_type'),
          class: "text-start",
        },
        {
          key: "Cancelled",
          label: this.$t('equity_bond.table.cancelled'),
          class: "text-start",
        },
        {
          key: "CancelledBy",
          label: this.$t('equity_bond.table.cancelled_by'),
          class: "text-start",
        },
        {
          key: "CancelDate",
          label: this.$t('equity_bond.table.canceled_date'),
          class: "text-start",
        },
        {
          key: "CancelTime",
          label: this.$t('equity_bond.table.cancelled_time'),
          class: "text-start",
        },
        {
          key: "CancelReason",
          label: this.$t('equity_bond.table.cancelled_reason'),
          class: "text-start",
        },
      ],

      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100],
      filter: null,
      loading: false,
      filterSettlementDate: null,
      filterTradeDate: null,
      canceledReasonText: null,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["getLastSearchEquity"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    back() {
      router.go(-1);
    },
    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const journalCreateRowColor = "table-journalCreate";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      if (!item || type !== "row") {
        return;
      }

      if (item.Cancelled == 1) {
        return canceledRowColor;
      }
      if (item.Reversed == 1) {
        return reversedRowColor;
      }
      if (item.ReversalInitiated == 1) {
        return reversedInitiateRowColor;
      }
      if (item.ExpiredOrder == 1) {
        return expiredOrderRowColor;
      }
      if (item.JournalCreated == 1) {
        return journalCreateRowColor;
      }
      if (item.Verified == 1) {
        return verifiedRowColor;
      }
      if (item.EntryType == "Execution") {
        return executedRowColor;
      }
    },
    getAmendDetailsData(id) {
      this.loading = true;
      this.$checkAccessRight("EquityBond", "View").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("equity&bond/getAmendDetailDatas", {
              auth: this.user,
              tradeId: id,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.tradeDetailDatas.push(res.data.trade);
             
                if (res.data.details.length > 0) {
                  res.data.details.forEach((data) => {
                    this.tradeDetailDatas.push(data);
                  });
                }
                this.totalRows = this.tradeDetailDatas.length;
                this.loading = false;
              } else {
                this.notFoundMessage();
                this.loading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.loading = false;
        }
      });
    },
    viewSelectedTrade(item) {
      this.$checkAccessRight("EquityBond", "View").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (this.tradeId == item.ID) {
              store
                .dispatch("equity&bond/getViewSelectedTrade", {
                  auth: this.user,
                  tradeId: item.ID,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                    this.$refs.ViewSelectedTrade.fetchedTradeData =
                      res.data.trade;

                    //set timeline datas
                    let actionsArray = [];
                    if (
                      res.data.trade.SavedBy != null &&
                      res.data.trade.SavedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Saved",
                        user: res.data.trade.SavedBy ?? "",
                        date:
                          res.data.trade.SaveDate.split(" ")[0] +
                          " " +
                          res.data.trade.SaveTime.split(" ")[1],
                        reason: "-",
                      });
                    }

                    if (
                      res.data.trade.AmendedBy != null &&
                      res.data.trade.AmendedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Amended",
                        user: res.data.trade.AmendedBy ?? "",
                        date:
                          res.data.trade.AmendmentDate.split(" ")[0] +
                          " " +
                          res.data.trade.AmendmentTime.split(" ")[1],
                        reason: res.data.trade.AmendmentReason,
                      });
                    }
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].VerificationUser != null &&
                        res.data.actions[0].VerificationUser != ""
                      ) {
                        this.$refs.ViewSelectedTrade.checkVerify = true;
                        actionsArray.push({
                          type: "Verified",
                          user: res.data.actions[0].VerificationUser ?? "",
                          date: res.data.actions[0].VerificationDateTime,
                          reason: "-",
                        });
                      }
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].JournalUser != null &&
                        res.data.actions[0].JournalUser != ""
                      ) {
                        actionsArray.push({
                          type: "Journal Created",
                          user: res.data.actions[0].JournalUser ?? "",
                          date: res.data.actions[0].JournalDateTime,
                          reason: "-",
                        });
                      }
                    }

                    if (
                      res.data.trade.CancelledBy != null &&
                      res.data.trade.CancelledBy != ""
                    ) {
                      actionsArray.push({
                        type: "Canceled",
                        user: res.data.trade.CancelledBy ?? "",
                        date:
                          res.data.trade.CancelDate.split(" ")[0] +
                          " " +
                          res.data.trade.CancelTime.split(" ")[1],
                        reason: res.data.trade.CancelReason,
                      });
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].ReversalInitiatedBy != null &&
                        res.data.actions[0].ReversalInitiatedBy != ""
                      ) {
                        actionsArray.push({
                          type: "Reversal Initiated",
                          user: res.data.actions[0].ReversalInitiatedBy ?? "",
                          date: res.data.actions[0].ReversalInitiatedDateTime,
                          reason: res.data.actions[0].ReversalInitiatedReason,
                        });
                      }
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].ReversedBy != null &&
                        res.data.actions[0].ReversedBy != ""
                      ) {
                        actionsArray.push({
                          type: "Reversed",
                          user: res.data.actions[0].ReversedBy ?? "",
                          date: res.data.actions[0].ReversedDateTime,
                          reason: "-",
                        });
                      }
                    }

                    this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              store
                .dispatch("equity&bond/getViewAmendedSelectedTrade", {
                  auth: this.user,
                  tradeId: item.ID,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                    this.$refs.ViewSelectedTrade.fetchedTradeData =
                      res.data.trade;

                    //set timeline datas
                    let actionsArray = [];
                    if (
                      res.data.trade.SavedBy != null &&
                      res.data.trade.SavedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Saved",
                        user: res.data.trade.SavedBy ?? "",
                        date:
                          res.data.trade.SaveDate.split(" ")[0] +
                          " " +
                          res.data.trade.SaveTime.split(" ")[1],
                        reason: "-",
                      });
                    }

                    if (
                      res.data.trade.AmendedBy != null &&
                      res.data.trade.AmendedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Amended",
                        user: res.data.trade.AmendedBy ?? "",
                        date:
                          res.data.trade.AmendmentDate.split(" ")[0] +
                          " " +
                          res.data.trade.AmendmentTime.split(" ")[1],
                        reason: res.data.trade.AmendmentReason,
                      });
                    }
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].VerificationUser != null &&
                        res.data.actions[0].VerificationUser != ""
                      ) {
                        this.$refs.ViewSelectedTrade.checkVerify = true;
                        actionsArray.push({
                          type: "Verified",
                          user: res.data.actions[0].VerificationUser ?? "",
                          date: res.data.actions[0].VerificationDateTime,
                          reason: "-",
                        });
                      }
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].JournalUser != null &&
                        res.data.actions[0].JournalUser != ""
                      ) {
                        actionsArray.push({
                          type: "Journal Created",
                          user: res.data.actions[0].JournalUser ?? "",
                          date: res.data.actions[0].JournalDateTime,
                          reason: "-",
                        });
                      }
                    }

                    if (
                      res.data.trade.CancelledBy != null &&
                      res.data.trade.CancelledBy != ""
                    ) {
                      actionsArray.push({
                        type: "Canceled",
                        user: res.data.trade.CancelledBy ?? "",
                        date:
                          res.data.trade.CancelDate.split(" ")[0] +
                          " " +
                          res.data.trade.CancelTime.split(" ")[1],
                        reason: res.data.trade.CancelReason,
                      });
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].ReversalInitiatedBy != null &&
                        res.data.actions[0].ReversalInitiatedBy != ""
                      ) {
                        actionsArray.push({
                          type: "Reversal Initiated",
                          user: res.data.actions[0].ReversalInitiatedBy ?? "",
                          date: res.data.actions[0].ReversalInitiatedDateTime,
                          reason: res.data.actions[0].ReversalInitiatedReason,
                        });
                      }
                    }

                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].ReversedBy != null &&
                        res.data.actions[0].ReversedBy != ""
                      ) {
                        actionsArray.push({
                          type: "Reversed",
                          user: res.data.actions[0].ReversedBy ?? "",
                          date: res.data.actions[0].ReversedDateTime,
                          reason: "-",
                        });
                      }
                    }

                    this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('equity_bond.toast_messages.database_connection_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found",
          text: this.$t('equity_bond.toast_messages.data_not_found'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t('equity_bond.general_messages.not_authorized_message'),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },

    tradeIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t('equity_bond.messages.trade_id_cannot_zero'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
      }
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    clearTradeDate() {
      this.filterTradeDate = null;
      this.runLastSearch();
    },
    clearSettlementDate() {
      this.filterSettlementDate = null;
      this.runLastSearch();
    },
    handleScroll(e) {
      // this.windowTop = window.top.scrollY
      // if(this.windowTop > 300) {
      //   console.log(this.$refs.refEquityBondTable.$el)
      // }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("AmendDetailsShow", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.table-purple {
  background: purple;
}
.table-executed {
  background-color: paleturquoise !important;
}
.table-verified {
  background-color: black !important;
}
.table-journalCreate {
  background-color: mediumpurple !important;
}
.table-expiredOrder {
  background-color: silver !important;
}
.table-reverseInitiated {
  background-color: #ffff99 !important;
}
.table-reversed {
  background-color: coral !important;
}
.table-canceled {
  background-color: orangered !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
